import { StyledSvg } from "baseui/icon";
import React from "react";

export const AccessControlLockIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
      <path d="M8.00033 1.33398C6.53346 1.33398 5.33366 2.53379 5.33366 4.00065V5.33398H4.16699C3.34449 5.33398 2.66699 6.01148 2.66699 6.83398V13.1673C2.66699 13.9898 3.34449 14.6673 4.16699 14.6673H11.8337C12.6562 14.6673 13.3337 13.9898 13.3337 13.1673V6.83398C13.3337 6.01148 12.6562 5.33398 11.8337 5.33398H10.667V4.00065C10.667 2.53379 9.46719 1.33398 8.00033 1.33398ZM8.00033 2.33398C8.9268 2.33398 9.66699 3.07418 9.66699 4.00065V5.33398H6.33366V4.00065C6.33366 3.07418 7.07385 2.33398 8.00033 2.33398ZM4.16699 6.33398H11.8337C12.1158 6.33398 12.3337 6.55182 12.3337 6.83398V13.1673C12.3337 13.4495 12.1158 13.6673 11.8337 13.6673H4.16699C3.88483 13.6673 3.66699 13.4495 3.66699 13.1673V6.83398C3.66699 6.55182 3.88483 6.33398 4.16699 6.33398ZM8.00033 9.00065C7.73511 9.00065 7.48076 9.10601 7.29322 9.29354C7.10568 9.48108 7.00033 9.73543 7.00033 10.0007C7.00033 10.2659 7.10568 10.5202 7.29322 10.7078C7.48076 10.8953 7.73511 11.0007 8.00033 11.0007C8.26554 11.0007 8.5199 10.8953 8.70743 10.7078C8.89497 10.5202 9.00033 10.2659 9.00033 10.0007C9.00033 9.73543 8.89497 9.48108 8.70743 9.29354C8.5199 9.10601 8.26554 9.00065 8.00033 9.00065Z" />
    </svg>
  </StyledSvg>
);

export const AccessControlLockFilledIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
      <path d="M8.00033 1.33398C6.53346 1.33398 5.33366 2.53379 5.33366 4.00065V5.33398H4.16699C3.33999 5.33398 2.66699 6.00698 2.66699 6.83398V13.1673C2.66699 13.9943 3.33999 14.6673 4.16699 14.6673H11.8337C12.6607 14.6673 13.3337 13.9943 13.3337 13.1673V6.83398C13.3337 6.00698 12.6607 5.33398 11.8337 5.33398H10.667V4.00065C10.667 2.53379 9.46719 1.33398 8.00033 1.33398ZM8.00033 2.33398C8.9268 2.33398 9.66699 3.07418 9.66699 4.00065V5.33398H6.33366V4.00065C6.33366 3.07418 7.07385 2.33398 8.00033 2.33398ZM8.00033 9.00065C8.55266 9.00065 9.00033 9.44832 9.00033 10.0007C9.00033 10.553 8.55266 11.0007 8.00033 11.0007C7.44799 11.0007 7.00033 10.553 7.00033 10.0007C7.00033 9.44832 7.44799 9.00065 8.00033 9.00065Z" />
    </svg>
  </StyledSvg>
);
